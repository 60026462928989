(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', gtmContainerId ?? '');

(function (w) {
  w.uet_report_form_conversion = function () {
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'contact', { event_category: 'contact_form_kontaktformular' });
  };
  w.uet_report_mail_conversion = function () {
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'contact', { event_category: 'email' });
  };
  w.uet_report_tel_conversion = function () {
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'contact', { event_category: 'tel' });
  };
})(window);
